<template>
  <div class="welcome-main">
    <div class="w-100">
      <div class="text-center">
        <h1 class="display-3">Welcome</h1>

        <MultiSelect
          textFloat="Tets "
          :options="options"
          textField="name"
          valueField="id"
          v-model="value"
          :value="value"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MultiSelect from "@/components/inputs/MultiSelect";
export default {
  components: { MultiSelect },
  data() {
    return {
      options: [
        { id: 1, name: "test" },
        { id: 2, name: "test3" },
        { id: 3, name: "tes2" },
      ],
      value: [],
    };
  },
  name: "Welcome",
  // computed: {
  //   logoImage() {
  //     return this.$store.state.theme.Logo;
  //   },
  // },
  methods: {},
};
</script>
<style scoped>
.welcome-main {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
